

































































































































































































































































import Vue from 'vue';
// import vertragsdaten from '@/components/form/vertragsdaten.vue';
// import formFactors from '@/components/form/form-factors.vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import IVertragsfaten from '@/model/IVertragsfaten';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
// import FormSwitch from '@/components/form/form-switch.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';


const riskTitles = [
  'Entspricht nicht den marktüblichen Standards.',
  'Entspricht teilweise den marktüblichen Standards',
  'Entspricht den marktüblichen Standards',
  'Übertrifft teilweise die marktüblichen Standards',
  'Übertrifft die marktüblichen Standards',
];

export default Vue.extend({
  name: 'individual-insurance-risk',
  mixins: [dataChangeMixin],
  components: {
    popUpModal,
    // formFactors,
    // FormSwitch,
  },
  data() {
    return {
      name: 'bauleistung-insurance-risk',
      key: 'CONSTRUCTION_WORK_INSURANCE',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      paramsVertragsdaten: {
        sumInsured: 0,
        sum371: 0,
        paymentMethod: '',
        versicherungssteuer: 0,
        netization: '',
        factorLevel: '',
      },
      schadenverlaufObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      betriebsstundenObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      maintenanceQualitatObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      mengenrabattObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      sonstigesBesichtigungObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      vertragsbezogeneRabatte: [
        {
          value: 1.2,
          text: riskTitles[0],
        },
        {
          value: 1.1,
          text: riskTitles[1],
        },
        {
          value: 1.0,
          text: riskTitles[2],
        },
        {
          value: 0.9,
          text: riskTitles[3],
        },
        {
          value: 0.8,
          text: riskTitles[4],
        },
      ],
      sonstigesItems: [
        {
          value: 1.2,
          text: riskTitles[0],
        },
        {
          value: 1.1,
          text: riskTitles[1],
        },
        {
          value: 1.0,
          text: riskTitles[2],
        },
        {
          value: 0.9,
          text: '',
        },
        {
          value: 0.8,
          text: riskTitles[3],
        },
        {
          value: 0.7,
          text: '',
        },
        {
          value: 0.6,
          text: riskTitles[4],
        },
      ],
      deckungserweiterungenItems: [
        {
          value: 1.0,
          text: riskTitles[0],
        },
        {
          value: 0.95,
          text: riskTitles[1],
        },
        {
          value: 0.9,
          text: riskTitles[2],
        },
        {
          value: 0.85,
          text: riskTitles[3],
        },
        {
          value: 0.8,
          text: riskTitles[4],
        },
      ],
      altbauItems: [
        {
          value: 1.5,
          text: riskTitles[0],
        },
        {
          value: 1.25,
          text: riskTitles[1],
        },
        {
          value: 1.0,
          text: riskTitles[2],
        },
        {
          value: 0.85,
          text: riskTitles[3],
        },
        {
          value: 0.7,
          text: riskTitles[4],
        },
      ],
      UnterlagenItems: [
        {
          value: 1.2,
          text: riskTitles[0],
        },
        {
          value: 1.1,
          text: riskTitles[1],
        },
        {
          value: 1.0,
          text: riskTitles[2],
        },
        {
          value: 0.9,
          text: riskTitles[3],
        },
        {
          value: 0.8,
          text: riskTitles[4],
        },
      ],
      ergebnisDerBewertung: {
        GesamtergebnisGemabRisikofaktorenbewertung: 0,
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id'],
  watch: {
    'schadenverlaufObj.value': function (val: any) {
      this.schadenverlaufObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'betriebsstundenObj.value': function (val: any) {
      this.betriebsstundenObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'maintenanceQualitatObj.value': function (val: any) {
      this.maintenanceQualitatObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'mengenrabattObj.value': function (val: any) {
      this.mengenrabattObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'sonstigesBesichtigungObj.value': function (val: any) {
      this.sonstigesBesichtigungObj.text = this.getContractRelatedDiscountByValue(val);
    },
  },
  computed: {
    averageRisk(): number {
      let value = this.schadenverlaufObj.value * this.betriebsstundenObj.value * this.maintenanceQualitatObj.value * this.mengenrabattObj.value;
      value = value < 0.6 ? 0.6 : value;
      return +value.toFixed(2);
    },
    backUrl(): string {
      const insuranceType = this.insuranceData?.basicData?.vertragsart || '';

      if (insuranceType === 'Baukombi' || insuranceType.indexOf('ABN') !== -1) {
        return 'bauleistung-calc-2a-ABN';
      }

      if (insuranceType.indexOf('ABU') !== -1) {
        return 'bauleistung-calc-2b-ABU';
      }

      return '';
    },
  },
  methods: {
    getContractRelatedDiscountByValue(value: any): string {
      return this.vertragsbezogeneRabatte.find((itm) => itm.value === value)?.text || '';
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 2);
    },
    paramsVertragsdatenChange(params: IVertragsfaten) {
      this.paramsVertragsdaten.sumInsured = params.sumInsured;
      this.paramsVertragsdaten.sum371 = params.sum371;
      this.paramsVertragsdaten.paymentMethod = params.paymentMethod;
      this.paramsVertragsdaten.versicherungssteuer = params.versicherungssteuer;
      this.paramsVertragsdaten.netization = params.netization;
      this.paramsVertragsdaten.factorLevel = params.factorLevel;
    },
    onSubmit(goNextPage = true) {
      const data = {
        paramsVertragsdaten: this.paramsVertragsdaten,
        schadenverlaufObj: this.schadenverlaufObj,
        betriebsstundenObj: this.betriebsstundenObj,
        maintenanceQualitatObj: this.maintenanceQualitatObj,
        mengenrabattObj: this.mengenrabattObj,
        sonstigesBesichtigungObj: this.sonstigesBesichtigungObj,
        ergebnisDerBewertung: {
          GesamtergebnisGemabRisikofaktorenbewertung: this.averageRisk,
        },
      };
      this.$emit('risk-factor-data-change', data, goNextPage);
    },
    setValues(insuranceData: any) {
      const { riskFactorData } = insuranceData;
      if (riskFactorData) {
        this.paramsVertragsdaten = riskFactorData.paramsVertragsdaten;
        this.schadenverlaufObj = riskFactorData.schadenverlaufObj;
        this.betriebsstundenObj = riskFactorData.betriebsstundenObj;
        this.maintenanceQualitatObj = riskFactorData.maintenanceQualitatObj;
        this.mengenrabattObj = riskFactorData.mengenrabattObj;
        this.sonstigesBesichtigungObj = riskFactorData.sonstigesBesichtigungObj;
        this.ergebnisDerBewertung = riskFactorData.ergebnisDerBewertung;
      }
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Bauleistung-Risikofaktoren.pdf');
    },
    savePdf() {
      saveZusammenfassungPDF.call(this, this.id, 'saveRiskPDF', this.onSubmit);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    this.onSubmit(false);
  },
});
